import React, { useState } from "react";
import { createComment } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Import Link if using React Router
import './commentStyle.css';

const CommentBox = ({ onAddComment }) => {
  const [comment, setComment] = useState(''); 
  const [error, setError] = useState('');
  const username = localStorage.getItem('username');
  const isUsernameValid = username && username.trim() !== '';
  const [loading, setLoading] = useState(false); // Loading state
  let { id } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!isUsernameValid) {
      setError('You must be logged in to post a comment.');
      return;
    }

    setLoading(true);

    try {
      const response = await createComment(comment, username, id);
      if (response === true) {
        const newComment = {
          created_by: username,
          comment: comment,
          created_at: new Date().toISOString(),
        };
        onAddComment(newComment);
        setComment('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to post comment.');
      }
    } catch (error) {
      setError('failed to post comment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="comment-container">
      <div className="comment">
        <form onSubmit={handleSubmit} className="comment-form">
          <textarea
            className="comment-input"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write a comment..."
            required
            disabled={!isUsernameValid} // Disable textarea if not logged in
          />
          <button
            type="submit"
            className="comment-submit"
            disabled={!isUsernameValid || loading}
          >
            {loading ? 'Saving...' : ' Post Comment'}
          </button>
        </form>
      </div>
      {error && <p className="error">{error}</p>}
      {!isUsernameValid && (
        <p className="login-prompt">
          Please <Link to="/login">log in</Link> to post a comment.
        </p>
      )}
    </div>
  );
};

export default CommentBox;
