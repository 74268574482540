  // PageLayout.js
  import React from 'react';
  import SideBar from './SideBar';
  import NavigationBar from './NavBar';
  import '../generalStyle.css';

  const PageLayout = ({ children }) => {
    return (
      <>
        <NavigationBar />
        <div className="page-container">
          <div className="content-wrap">
            <div className="setup">
              <SideBar />
            </div>
            <div className="content">{children}</div>
          </div>
        </div>
      </>
    );
  };

  export default PageLayout;
