import React from 'react';
import '../generalStyle.css';

const AboutUs = () => {
  return(
    <div className="container">
    <h1>About Us</h1>
    <h2>Welcome to Glimmer!</h2>
    <p>
      I'm <strong>Munya</strong>, a graduate of the University of Kent and the founder of Glimmer.
      I started this platform to address a common question:
      <em> "Do you know anyone who does hair?"</em> With mobile hairdressing often overlooked, I
      wanted to create a space that honors this essential service.
    </p>
    <p>
      At Glimmer, we’re passionate about celebrating beauty, creativity, and individuality. Our
      goal is simple: to connect talented freelance hairdressers with clients seeking
      high-quality, accessible, and affordable hair care services.
    </p>
    <p>
      Whether you’re looking for professional braiding or a fresh new look, Glimmer is designed to
      help you find the perfect stylist for your needs. Our user-friendly platform lets you browse
      profiles, read reviews, and book appointments with ease.
    </p>
    <p>
      We have big plans for the future, aiming to transform Glimmer into a leading beauty hub.
      Thank you for choosing us as your go-to destination for professional hair care. If you have
      any questions or need assistance, don’t hesitate to reach out.
    </p>
    <p>Talk soon!</p>
    <p>
      <strong>~ Munya</strong>
    </p>
  </div>
  )
}

export default AboutUs;