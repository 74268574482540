import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { createPost, getTags } from '../../utils/api.jsx';

const CreatePostPage = () => {
  const created_by = localStorage.getItem('username');
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]); // State for multiple images
  const [error, setError] = useState('');
  const [tags, setTags] = useState([]);
  const [tag_ids, setSelectedTagIds] = useState([]);
  const fileInputRef = useRef(null); // Ref for the file input
  const [loading, setLoading] = useState(false);
  const isUsernameValid = localStorage.getItem('username') && localStorage.getItem('username').trim() !== '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTags();
        setTags(response);
      } catch {
        setError('Error while trying to get tags');
      }
    };

    fetchData();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    
    // Check if the user is uploading more than 5 images
    if (files.length + images.length > 5) {
      setError('You can only upload up to 5 images.');
      return;
    }

    setImages((prevImages) => [...prevImages, ...files]); // Append the new images

    // Clear the file input after adding images
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input field so more images can be selected
    }
  };

  const handleImageRemove = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages); // Update the images state
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!isUsernameValid) {
      setError('You must be logged in to post a comment.');
      return;
    }

    const formData = new FormData();
    formData.append('post[title]', title);
    formData.append('post[content]', content);
    formData.append('post[created_by]', created_by);

    tag_ids.forEach(tag_id => formData.append('post[tag_ids][]', tag_id));

    images.forEach((image, index) => {
      formData.append(`post[images][${index}]`, image);
    });

    setLoading(true);

    try{
      const response = await createPost(formData);
      if (response === true) {
        navigate('/');
      } else if (response === false) {
        setError('Failed to create post. Please try again.');
      } else {
        let errors = "";
        for (let i = 0; i < response.errors.length; i++) {
          errors += response.errors[i] + ", ";
        }
        setError(errors);
      }
    } catch (error) {
      setError('Error creating post');
    } finally {
      setLoading(false);
    }
  };

  const handleTagChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedTagIds((prevSelectedTagIds) =>
      prevSelectedTagIds.includes(value)
        ? prevSelectedTagIds.filter((id) => id !== value)
        : [...prevSelectedTagIds, value]
    );
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit} encType="multipart/form-data">
        <h2 className="title">Create A Post</h2>

        <div className="form-group">
          <input
            placeholder="Title"
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        {tags && tags.length > 0 ? (
          <div className="tags-container">
            {tags.map((tag) => (
              <div key={tag.id}>
                <input
                  type="checkbox"
                  id={`tag-${tag.id}`}
                  name="tags"
                  value={tag.id}
                  onChange={handleTagChange}
                />
                <p htmlFor={`tag-${tag.id}`}>{tag.name}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="center-text">No tags available</p>
        )}

        <div className="form-group">
          <textarea
            className="content-input"
            placeholder="Content"
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>

        <p className="center-text">Upload up to 5 images</p>
        <div className="form-group">
          <input
            type="file"
            id="images"
            accept="image/*"
            multiple
            ref={fileInputRef} // Ref for the input field
            onChange={handleImageChange}
            disabled={images.length >= 5} // Disable the input if 5 images are uploaded
          />
        </div>

        {images.length > 0 && <p className="center-text">{images.length} files</p>}
        <div className="image-preview-container">
          {images.map((image, index) => (
            <div key={index} className="image-preview">
              <img src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
              <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
            </div>
          ))}
        </div>

        {error && <p className="center-text">{error}</p>}

        <div className="form-group">
          <button type="submit" className="button" disabled={loading || !isUsernameValid}>
            {loading ? 'Posting...' : 'Create A Post'}
          </button>
        </div>

        {!isUsernameValid && (
        <p className="login-prompt center-text">
          Please <Link to="/login">log in</Link> to create a post.
        </p>
      )}
      </form>
    </div>
  );
};

export default CreatePostPage;
