import React, { useEffect, useState } from 'react';
import './ProfileImageCarousel.css';
import { getProfile } from '../../utils/api.jsx';

const ProfileImageCarousel = ({ userId }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchProfileImages = async () => {
      try {
        const response = await getProfile(userId);

        if (response && !response.errors) {
          setImages(response.images || []);
        } else {
          setError(response.errors ? response.errors.join(', ') : 'Failed to fetch profile images');
        }
      } catch (error) {
        setError('Error fetching profile images');
      }
    };

    fetchProfileImages();
  }, [userId]);

  const itemsToShow = 4;
  const totalSlides = images.length;
  const maxIndex = totalSlides - itemsToShow;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : 0));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : maxIndex));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  // if (images.length === 0) {
  //   return <div>No images to display.</div>;
  // }

  return (
    // console.log(images.length)
    (images.length > 0) && (
      <div className="carousel-section">
        <h2>Profile Images</h2>
        <div className="carousel-container">
          <button className="carousel-button prev" onClick={prevSlide}>❮</button>
          <div className="carousel-slide" style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
            {images.map((image, index) => (
              <div
                key={image.id}
                className="carousel-image"
                style={{ flex: `0 0 ${100 / itemsToShow}%` }}
              >
                <img src={image.image_url} alt={`Slide ${index}`} />
              </div>
            ))}
          </div>
          <button className="carousel-button next" onClick={nextSlide}>❯</button>
        </div>
      </div>
    )
  );
  
};

export default ProfileImageCarousel;
