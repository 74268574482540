// src/components/user/EditProfile.jsx
import React, { useState, useEffect, useRef } from 'react';
import { updateProfile, getProfile, getTags } from '../../utils/api.jsx';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';

// Define libraries outside the component to avoid unnecessary reloading
const libraries = ['places'];

const EditProfile = () => {
  const user_id = localStorage.getItem('userId');
  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [profileError, setProfileError] = useState('');
  const [instagramMessage, setInstagramMessage] = useState('');
  const [radius, setRadius] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [route, setRoute] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const autocompleteRef = useRef(null);
  const [address, setAddress] = useState('');
  const [tags, setTags] = useState([]);
  const [tag_ids, setSelectedTagIds] = useState([]);
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // Use the predefined libraries array
  });

  useEffect(() => {
    if (user_id) {
      const fetchData = async () => {
        let response = await getProfile(user_id);
        if (response && !response.errors) {
          setWebsite(response.website);
          setInstagram(response.instagram);
          setTiktok(response.tiktok);
          if (response.radius !== undefined && response.radius !== null && response.radius > 0) {
            setRadius(response.radius);
          }          
          setStreetNumber(response.street_number);
          setRoute(response.route);
          setCity(response.city);
          setCounty(response.county);
          setPostalCode(response.postal_code);
          setCountry(response.country);
          setSelectedTagIds(response.tag_ids);
          setDescription(response.description);
          setExistingImages(response.images || []);

          const addressString = [
            response.street_number,
            response.route,
            response.city,
            response.county,
            response.postal_code,
            response.country,
          ]
            .filter(Boolean)
            .join(', ');

          setAddress(addressString);
        } else {
          setProfileError(response.errors.join(', '));
        }

        response = await getTags(user_id);
        if (response && !response.errors) {
          setTags(response || []);
        } else {
          setProfileError(response.errors.join(', '));
        }
      };

      fetchData();
    }
  }, [user_id]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    const maxSize = 2 * 1024 * 1024; // 2MB limit
    const maxImages = 10;

    let errors = [];
    let validFiles = [];

    const totalImages = existingImages.length + selectedFiles.length - imagesToDelete.length;
    if (totalImages > maxImages) {
      errors.push(`You can upload a maximum of ${maxImages} images. You have already uploaded ${existingImages.length - imagesToDelete.length} images.`);
    } else {
      selectedFiles.forEach((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          errors.push(`${file.name}: Invalid file type.`);
        } else if (file.size > maxSize) {
          errors.push(`${file.name}: File size exceeds 2MB.`);
        } else {
          validFiles.push(file);
        }
      });
    }

    if (errors.length > 0) {
      setFileErrors(errors);
    } else {
      setFileErrors([]);
      setImages(validFiles);
    }
  };

  const handleDeleteImage = (imageId) => {
    setImagesToDelete([...imagesToDelete, imageId]);
    setExistingImages(existingImages.filter(image => image.id !== imageId));
  };

  const handleProfileSubmit = async (event) => {
    event.preventDefault();

    // Prepare FormData
    const formData = new FormData();

    // Helper function to check if a value is not empty
    const isNotEmpty = (value) => {
      return value !== undefined && value !== null && value !== '';
    };

    const profileData = {
      'profile[user_id]': user_id,
      'profile[website]': website,
      'profile[instagram]': instagram,
      'profile[tiktok]': tiktok,
      'profile[radius]': radius,
      'profile[street_number]': streetNumber,
      'profile[route]': route,
      'profile[city]': city,
      'profile[county]': county,
      'profile[postal_code]': postalCode,
      'profile[country]': country,
      'profile[description]': description,
    };
  
    // Conditionally append fields to FormData
    for (const [key, value] of Object.entries(profileData)) {
      if (isNotEmpty(value)) {
        formData.append(key, value);
      }
    }
  
    // Handle tag_ids
    tag_ids.forEach(tag_id => {
      formData.append('profile[tag_ids][]', tag_id);
    });

    // Include images to be deleted
    imagesToDelete.forEach((imageId, index) => {
      formData.append(`profile[images_attributes][${index}][id]`, imageId);
      formData.append(`profile[images_attributes][${index}][_destroy]`, '1');
    });

    // Include new images
    images.forEach((image, index) => {
      const formIndex = imagesToDelete.length + index;
      formData.append(`profile[images_attributes][${formIndex}][image]`, image);
    });

    setLoading(true);

    try {
      const response = await updateProfile(formData);

      if (!response.errors) {
        // Update only if images are present
        if (response.images) {
          setExistingImages(response.images);
        }
        
        // Reset image states
        setImages([]);
        setImagesToDelete([]);
      
        // Conditionally update each field if it exists in the response
        if (response.website) {
          setWebsite(response.website);
        }
        if (response.instagram) {
          setInstagram(response.instagram);
        }
        if (response.tiktok) {
          setTiktok(response.tiktok);
        }
        if (response.radius !== undefined && response.radius !== null) {
          setRadius(response.radius);
        }
        if (response.street_number) {
          setStreetNumber(response.street_number);
        }
        if (response.route) {
          setRoute(response.route);
        }
        if (response.city) {
          setCity(response.city);
        }
        if (response.county) {
          setCounty(response.county);
        }
        if (response.postal_code) {
          setPostalCode(response.postal_code);
        }
        if (response.country) {
          setCountry(response.country);
        }
        if (response.description) {
          setDescription(response.description);
        }
      
        const addressString = [
          response.street_number,
          response.route,
          response.city,
          response.county,
          response.postal_code,
          response.country
        ]
        .filter(Boolean) // Remove any undefined or null values
        .join(', ');
      
        if (addressString) {
          setAddress(addressString);
        }
      
        setProfileError('Profile updated successfully.');
      } else {
        setProfileError(response.errors.join(", "));
      }
      
    } catch (error) {
      setProfileError('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.address_components) {
      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) {
          setStreetNumber(component.long_name);
        }
        if (types.includes('route')) {
          setRoute(component.long_name);
        }
        if (types.includes('locality') || types.includes('postal_town')) {
          setCity(component.long_name);
        }
        if (types.includes('administrative_area_level_2')) {
          setCounty(component.long_name);
        }
        if (types.includes('postal_code')) {
          setPostalCode(component.long_name);
        }
        if (types.includes('country')) {
          setCountry(component.long_name);
        }
      });

      const addressString = [
        place.address_components.find((c) => c.types.includes('street_number'))?.long_name,
        place.address_components.find((c) => c.types.includes('route'))?.long_name,
        place.address_components.find((c) => c.types.includes('locality') || c.types.includes('postal_town'))?.long_name,
        place.address_components.find((c) => c.types.includes('administrative_area_level_2'))?.long_name,
        place.address_components.find((c) => c.types.includes('postal_code'))?.long_name,
        place.address_components.find((c) => c.types.includes('country'))?.long_name,
      ]
        .filter(Boolean)
        .join(', ');

      setAddress(addressString);
    }
  };
  
  const handleTagChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedTagIds((prevSelectedTagIds) =>
      prevSelectedTagIds.includes(value)
        ? prevSelectedTagIds.filter((id) => id !== value)
        : [...prevSelectedTagIds, value]
    );
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <form className="form" onSubmit={handleProfileSubmit}>
        <h2 className="title">Edit Profile</h2>
        <div className="form-group">
          <input
            placeholder="Website"
            type="text"
            id="website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Tiktok"
            type="text"
            id="tiktok"
            value={tiktok}
            onChange={(e) => setTiktok(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Instagram"
            type="text"
            id="instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Radius (Km)"
            type="number"
            id="radius"
            value={radius}
            onChange={(e) => setRadius(e.target.value)}
          />
        </div>
        <p className="creator-tag center-text">Only add a radius if you are ok to commute</p>
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
          options={{
            componentRestrictions: { country: 'uk' },
          }}
          className="form-group"
        >
          <input
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Autocomplete>
        <div className="checkbox-container">
            {tags && tags.length > 0 ? (
              tags.map((tag) => (
                <div key={tag.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    id={`tag-${tag.id}`}
                    name="tags"
                    value={tag.id}
                    checked={tag_ids.includes(tag.id)}
                    onChange={handleTagChange}
                  />
                  <label htmlFor={`tag-${tag.id}`}>{tag.name}</label>
                </div>
              ))
            ) : (
              <p>No tags available</p>
            )}
          </div>

        <div className="form-group">
          <textarea
            id="description"
            name="description"
            placeholder="Enter a description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="5"
            className="content-input"
          />
        </div>

        <p className="center-text">Upload up to 10 images</p>
        <div className="form-group">
          <input
            type="file"
            id="images"
            accept=".jpg,.png,.jpeg,.gif"
            onChange={handleFileChange}
            multiple
          />
        </div>

        {fileErrors && <p className="center-text">{fileErrors}</p>}
        

        <div className="image-preview-container">
          {existingImages.map((image) => (
            <div key={image.id} className="image-preview">
              <img src={image.image_url} alt={`Profile ${image.id}`} />
              <button type="button" onClick={() => handleDeleteImage(image.id)}>Delete</button>
            </div>
          ))}
        </div>

          {profileError && <p className="center-text">{profileError}</p>}
          {instagramMessage && <p className="center-text">{instagramMessage}</p>}
          <div className="form-group">
            <button type="submit" className="button" disabled={loading}>
              {loading ? 'Saving...' : 'Edit'}
            </button>
          </div></form>
    </div>
  );
};

export default EditProfile;
