// src/components/profile/AllProfilesMap.jsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { getProfiles, getTags } from '../../utils/api.jsx';

// Define libraries outside the component to avoid unnecessary reloading
const libraries = ['places'];

const AllProfilesMap = () => {
  const [profiles, setProfiles] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [mapError, setMapError] = useState('');
  const [selectedArea, setSelectedArea] = useState('england');
  const [viewMode, setViewMode] = useState('map');
  const mapRef = useRef(null);
  const [tags, setTags] = useState([]);
  const defaultProfilePicture = process.env.PUBLIC_URL + '/Default_pfp.png';


  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // Use the predefined libraries array
  });

  const areas = {
    england: { lat: 52.3555, lng: -1.1743, zoom: 6 },
    london: { lat: 51.5007, lng: -0.1246, zoom: 14 },
    essex: { lat: 51.7360, lng: 0.4798, zoom: 12 },
    kent: { lat: 51.2802, lng: 1.0789, zoom: 14 },
  };

  const [mapCenter, setMapCenter] = useState(areas[selectedArea]);

  const fetchProfiles = useCallback(async () => {
    try {
      const response = await getProfiles();
      if (response && !response.errors) {
        const validProfiles = response
          .map((profile) => ({
            ...profile,
            fullAddress: [
              profile.street_number,
              profile.route,
              profile.city,
              profile.county,
              profile.postal_code,
              profile.country,
            ]
              .filter(Boolean)
              .join(', '),
          }))
          .filter((profile) => profile.fullAddress);

        setProfiles(validProfiles);

        const geocoder = new window.google.maps.Geocoder();
        const geocodeProfiles = validProfiles.map(
          (profile) =>
            new Promise((resolve) => {
              geocoder.geocode({ address: profile.fullAddress }, (results, status) => {
                if (status === 'OK') {
                  const location = results[0].geometry.location;
                  resolve({ ...profile, coordinates: { lat: location.lat(), lng: location.lng() } });
                } else {
                  console.error(`Geocode failed for address "${profile.fullAddress}" with status: ${status}`);
                  resolve(null);
                }
              });
            })
        );

        const results = await Promise.all(geocodeProfiles);
        setCoordinates(results.filter((profile) => profile && profile.coordinates));
      } else {
        setMapError('Failed to load profiles.');
      }

      const tagResponse = await getTags();
      if (tagResponse && !tagResponse.errors) {
        setTags(tagResponse || []);
      } else {
        setMapError('Failed to load tags.');
      }
    } catch (error) {
      setMapError('An error occurred while fetching profiles.');
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      fetchProfiles();
    }
  }, [isLoaded, fetchProfiles]);

  useEffect(() => {
    const map = mapRef.current;
    if (map && selectedArea) {
      const { lat, lng, zoom } = areas[selectedArea];
      map.panTo({ lat, lng });
      map.setZoom(zoom);  // Check if map is loaded before setting zoom
    }
  }, [selectedArea]);

  const handleAreaClick = (area) => {
    setSelectedArea(area);
  };

  const handleViewToggle = (mode) => {
    setViewMode(mode);
  };

  const handleMarkerClick = (profile) => {
    setSelectedProfile(profile);
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="view-container">
      {/* View Mode Toggle */}
      <div className="view-toggle">
        <button
          onClick={() => handleViewToggle('map')}
          className={`button ${viewMode === 'map' ? 'active' : ''}`}
        >
          Map View
        </button>
        <button
          onClick={() => handleViewToggle('list')}
          className={`button ${viewMode === 'list' ? 'active' : ''}`}
        >
          List View
        </button>
      </div>

      {/* Location Buttons */}
      <div className={`location-buttons ${viewMode === 'map' ? '' : 'hidden'}`}>
        {Object.keys(areas).map((area) => (
          <button
            key={area}
            onClick={() => handleAreaClick(area)}
            className={`location-button ${selectedArea === area ? 'active' : ''}`}
          >
            {area.charAt(0).toUpperCase() + area.slice(1)}
          </button>
        ))}
      </div>

      {/* Map View */}
      <div className={`map ${viewMode === 'map' ? '' : 'hidden'}`}>
        <GoogleMap
          mapContainerStyle={{ height: '79vh', width: '100%' }}
          center={mapCenter}  // Use the state variable
          zoom={areas[selectedArea].zoom}  // Keep the zoom logic here
          onLoad={(mapInstance) => (mapRef.current = mapInstance)}
        >
          
          {coordinates.map((profile, index) => (
            <Marker
              key={index}
              position={profile.coordinates}
              onClick={() => handleMarkerClick(profile)}
              zIndex={1000}
              clickable={true}
            />
          ))}

          {selectedProfile && (
            <InfoWindow
              position={selectedProfile.coordinates}
              onCloseClick={() => setSelectedProfile(null)}
            >
              <div className="info-window-card">
                <img src={selectedProfile.user.profile_picture.url || defaultProfilePicture} className="profile-picture-icon" style={{ paddingBottom: 10 , paddingTop: 0}}/>
                <h3 className="info-window-city">{selectedProfile.city}</h3>

                {/* Tags Logic */}
                {selectedProfile.tag_ids?.length > 0 && (
                  <div className="tags">
                    {tags
                      .filter((tag) => selectedProfile.tag_ids.includes(tag.id))
                      .map((tag) => (
                        <a key={tag.id} href={`/filteredposts/${tag.id}`}>
                          <span className="tag">{tag.name}</span>
                        </a>
                      ))}
                  </div>
                )}

                <div className="info-window-details">
                  {/* Website Field Check */}
                  {selectedProfile.website && (
                    <p>
                      <strong>Website:</strong>{' '}
                      <a
                        href={
                          selectedProfile.website.startsWith('http')
                            ? selectedProfile.website
                            : `http://${selectedProfile.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="info-window-link"
                      >
                        {selectedProfile.website}
                      </a>
                    </p>
                  )}

                  {/* TikTok Field Check */}
                  {selectedProfile.tiktok && (
                    <p>
                      <strong>TikTok:</strong>{' '}
                      <a
                        href={
                          selectedProfile.tiktok.startsWith('http')
                            ? selectedProfile.tiktok
                            : `http://${selectedProfile.tiktok}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="info-window-link"
                      >
                        {selectedProfile.tiktok}
                      </a>
                    </p>
                  )}

                  {/* Instagram Field Check */}
                  {selectedProfile.instagram && (
                    <p>
                      <strong>Instagram:</strong>{' '}
                      <a
                        href={
                          selectedProfile.instagram.startsWith('http')
                            ? selectedProfile.instagram
                            : `http://${selectedProfile.instagram}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="info-window-link"
                      >
                        {selectedProfile.instagram}
                      </a>
                    </p>
                  )}
                </div>

                {/* View Profile Button */}
                <a href={`/profile/${selectedProfile.user_id}`} className="button">
                  View Profile
                </a>
              </div>
            </InfoWindow>
          )}

        </GoogleMap>

        {mapError && <div>{mapError}</div>}
      </div>

      {/* List View */}
      <div className={`profiles-list ${viewMode === 'list' ? '' : 'hidden'}`}>
        {coordinates.map((profile, index) => {
          // Declare matchedTags here, outside of JSX
          const matchedTags = tags.filter(tag => profile.tag_ids.includes(tag.id));

          return (
            <div key={index} className="profile-card">
              <img src={profile.user.profile_picture.url || defaultProfilePicture} className="profile-picture-icon" />
              <h3 className="profile-name">{profile.user?.username}'s Profile</h3>
              <h6 className="profile-city">{profile.city}</h6>

              {matchedTags.length > 0 && (
                <div className="tags">
                  {matchedTags.map((tag) => (
                    <a key={tag.id} href={`/filteredposts/${tag.id}`}>
                      <span className="tag">{tag.name}</span>
                    </a>
                  ))}
                </div>
              )}

              <div className="profile-details">
                {profile.website && (
                  <p>
                    <strong>Website:</strong>{' '}
                    <a
                      href={profile.website.startsWith('http') ? profile.website : `http://${profile.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="profile-link"
                    >
                      {profile.website}
                    </a>
                  </p>
                )}

                {profile.tiktok && (
                  <p>
                    <strong>TikTok:</strong>{' '}
                    <a
                      href={profile.tiktok.startsWith('http') ? profile.tiktok : `http://${profile.tiktok}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="profile-link"
                    >
                      {profile.tiktok}
                    </a>
                  </p>
                )}

                {profile.instagram && (
                  <p>
                    <strong>Instagram:</strong>{' '}
                    <a
                      href={profile.instagram.startsWith('http') ? profile.instagram : `http://${profile.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="profile-link"
                    >
                      {profile.instagram}
                    </a>
                  </p>
                )}
              </div>


              <a href={`/profile/${profile.user_id}`} className="button">
                View Profile
              </a>
            </div>
          );
        })}
      </div>

    </div>
  );
};

export default AllProfilesMap;
