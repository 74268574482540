import React from 'react';
import '../generalStyle.css';

const PrivacyPolicy = () => {
  return(
    <div className="container">  
      <h1>Privacy Policy for Glimmer</h1>
      <p><strong>Effective Date:</strong> 1/10/2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Glimmer. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and disclose your information when you visit our website and use our services.</p>
      <p>If you have any questions or concerns about this policy or our practices with regard to your personal information, please contact us at nmwaijumba02@gmail.com.</p>

      <h2>2. Information We Collect</h2>

      <h3>Personal Information</h3>
      <p>We may collect the following personal information directly from you:</p>
      <ul>
        <li><strong>Name</strong></li>
        <li><strong>Phone Number</strong></li>
        <li><strong>Email Address</strong></li>
      </ul>
      <p>This information is collected when you create an account, contact us, or interact with certain features of our website.</p>

      <h3>Non-Personal Information</h3>
      <p>We may also collect non-personal information automatically as you navigate through the site. This may include:</p>
      <ul>
        <li><strong>Cookies:</strong> Small files stored on your device to track user preferences and activity on our site.</li>
        <li><strong>Cache:</strong> Temporary storage to improve loading times and site performance.</li>
      </ul>
      <p>We may use cookies and similar tracking technologies to enhance your experience on our site, remember your preferences, and provide a personalized experience.</p>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li><strong>Account Management:</strong> To create and manage your user account.</li>
        <li><strong>Profile Creation:</strong> To allow you to create profiles, communicate with other users, and participate in our community features.</li>
        <li><strong>Service Delivery:</strong> To provide, operate, and maintain our website and services.</li>
        <li><strong>Communication:</strong> To communicate with you about your account, respond to your inquiries, and provide updates.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>Currently, we do not share your personal information with third parties. However, this policy may be updated if our practices change. If we decide to share your information with third parties, we will update this policy and notify you as required by law.</p>

      <h2>5. Data Security</h2>
      <p>We are committed to protecting your personal information. We use the following security measures:</p>
      <ul>
        <li><strong>Password Encryption:</strong> Your password is encrypted before it is stored on our servers.</li>
        <li><strong>Hosting:</strong> Our website is hosted on Heroku and Vercel, which provide robust security measures to protect your data.</li>
      </ul>

      <h2>6. Your Rights</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li><strong>Access and Correction:</strong> You can access and update your personal information through your account settings.</li>
        <li><strong>Data Deletion:</strong> You can request the deletion of your account and personal information by contacting us at nmwaijumba02@gmail.com.</li>
      </ul>

      <h2>7. Children’s Privacy</h2>
      <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

      <h2>8. International Users</h2>
      <p>If you are accessing our website from outside the United Kingdom, your information may be transferred to, stored, and processed in the United Kingdom. By using our services, you consent to the transfer of your information to our facilities and those third parties with whom we share it, as described in this policy.</p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>

      <h2>Data Deletion</h2>
      <p>In compliance with Facebook and Instagram policies, Glimmer provides users with the ability to request the deletion of their personal data collected through the service. If you wish to delete your data, please contact us at nmwaijumba02@gmail.com with the subject line "Data Deletion Request" and include your account information (e.g., username or email associated with your account).</p>

      <p>Once we receive your request, we will ensure that all personal data associated with your account is permanently deleted from our system, in accordance with applicable laws and regulations.</p>

      <p>Alternatively, you can delete your account and all associated data by following the instructions in your account settings on Glimmer.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> nmwaijumba02@gmail.com</li>
      </ul>
    </div>
  )
}

export default PrivacyPolicy;