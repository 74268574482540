import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ProfileImageCarousel from '../instagram/ProfileImageCarousel.jsx';
import ProfileMap from './ProfileMap';
import { getProfile, getUser, getTags, getUserPosts } from '../../utils/api.jsx';

const ProfilePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const defaultProfilePicture = process.env.PUBLIC_URL + '/Default_pfp.png';
  
  // Data states
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setNumber] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [instagram, setinstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [tags, setTags] = useState([]);
  const [profileError, setProfileError] = useState('');
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [profilepicture, setProfilePicture] = useState(true);

  // Loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          // Fetch user data
          const response = await getUser(id);
          if (response && !response.errors) {
            setUsername(response.username);
            setEmail(response.email);
            setNumber(response.phone_number);
          } else {
            setProfileError(response.errors.join(", "));
          }

          // Fetch profile data
          const profileResponse = await getProfile(id);
          if (profileResponse && !profileResponse.errors) {
            setWebsite(profileResponse.website);
            setTiktok(profileResponse.tiktok);
            setinstagram(profileResponse.instagram);
            setDescription(profileResponse.description);
            setTagIds(profileResponse.tag_ids);
            setProfilePicture(profileResponse.user.profile_picture_url);
          } else {
            setProfileError(profileResponse.errors.join(", "));
          }

          // Fetch tags
          const tagResponse = await getTags();
          if (tagResponse && !tagResponse.errors) {
            setTags(tagResponse || []);
          } else {
            setProfileError(tagResponse.errors.join(", "));
          }

          // Fetch posts
          const postsResponse = await getUserPosts(id, currentPage, 10);
          if (postsResponse && !postsResponse.errors) {
            setPosts(postsResponse.posts);
            setTotalPages(postsResponse.total_pages);
          } else {
            setProfileError(postsResponse.errors.join(", "));
          }

        } catch (error) {
          setProfileError("An error occurred while fetching data.");
        } finally {
          // Set loading to false after data is fetched
          setLoading(false);
        }
      };
  
      fetchData();
    }
  }, [id, currentPage]);

  const handlePostClick = (postId) => {
    navigate(`/post/${postId}`);
  };

  const handlePrevious = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const handleNext = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const matchedTags = tags.filter(tag => tagIds.includes(tag.id));

  const fullUrl = instagram ? (instagram.startsWith('http://') || instagram.startsWith('https://') ? instagram : `https://${instagram}`) : '';
  const websiteurl = website ? (website.startsWith('http://') || website.startsWith('https://') ? website : `https://${website}`) : '';
  const fullTikTok = tiktok ? (tiktok.startsWith('http://') || tiktok.startsWith('https://') ? tiktok : `https://${tiktok}`) : '';

  // Precompute conditions for rendering
  const hasContactDetails = website || description || fullUrl || fullTikTok || matchedTags.length > 0 || posts.length > 0;
  const hasDescriptionSection = description || fullUrl || fullTikTok || matchedTags.length > 0;
  const hasPosts = posts.length > 0;

  // Render a loading spinner or message while data is being fetched
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or any custom loader
  }

  return (
    <div className="profile-page-container">
      <div className="main-content">
        <div className="middle-section">
          <div className="content-container">
            <div className="header-section">
              <div className="header-profile">
                <img src={profilepicture || defaultProfilePicture} className="profile-picture-icon" />
                <h1 className="profile-page-title">{username}'s Profile Page</h1>
              </div>
              {hasContactDetails ? (
                <>
                  <div className="contact-details">
                    <span className="contact-detail"> Email: {email}</span>
                    <span className="contact-detail"> Number: {phone_number} </span>
                    {website && (
                      <span className="contact-detail">
                        Website: <a href={websiteurl} style={{ textDecoration: 'none', color: 'inherit' }}>{website}</a>
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span className="contact-detail"> {username}'s Profile Page Has Not Been Set Up.</span>
              )}
            </div>

            <div className="map-container-small" style={{marginBottom: 20}}>
              <ProfileMap id={id} />
            </div>

            {hasDescriptionSection && (
              <div className="description-section">
                {description && (
                  <>
                    <h3 style={{ marginTop: 0 }}>Description</h3>
                    <p>{description}</p>
                  </>
                )}
                
                {(fullUrl || fullTikTok) && (
                  <div className="links">
                    {fullUrl && (
                      <a className="link" href={fullUrl} target="_blank" rel="noopener noreferrer">Instagram</a>
                    )}
                    {fullTikTok && (
                      <a className="link" href={fullTikTok} target="_blank" rel="noopener noreferrer">TikTok</a>
                    )}
                  </div>
                )}

                {matchedTags.length > 0 && (
                  <div className="tags">
                    <h3 style={{ marginBottom: 0 }}>Tags:</h3>
                    {matchedTags.map((tag) => (
                      <a key={tag.id} href={`/filteredposts/${tag.id}`}>
                        <span className='tag'>{tag.name}</span>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            )}

            <ProfileImageCarousel userId={id} />

            {hasPosts && (
              <div className="post-container">
                <div className="pagination">
                  <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
                  {renderPageNumbers()}
                  <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
                </div>
              </div>
            )}

            {hasPosts && posts.map((post) => (
              <div className="profile-posts" key={post.id}>
                <div className="post-container">
                  <div key={post.id} className="post" onClick={() => handlePostClick(post.id)}>
                    <img src={post.user.profile_picture.url || defaultProfilePicture} className="profile-picture-icon" />
                    <div className='post-header'>
                      <h3 className="post-title">{post.title}</h3>
                      <span className="creator-tag">By: {post.created_by}</span>
                    </div>
                    {/* Tags Display */}
                    {post.tags && post.tags.length > 0 && (
                      <div >
                        {post.tags.map((tag) => (
                          <span key={tag.id} className="tag">
                            {tag.name}
                          </span>
                        ))}
                      </div>
                    )}
                    <p className="post-preview">{post.content}</p>
                  </div>
                </div>
              </div>
            ))}

            {hasPosts && (
              <div className="post-container">
                <div className="pagination">
                  <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
                  {renderPageNumbers()}
                  <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
                </div>
              </div>
            )}

            {profileError && <p className="error">{profileError}</p>}
          </div>
        </div>
        <div className="map-container">
          <ProfileMap id={id} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
