import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from '../../utils/api.jsx'; // Import the registerUser function
import "./userStyle.css";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState(''); // State to store any error message
  const [email, setEmail] = useState(''); // State to store the email input
  const [phone_number, setNumber] = useState(''); // State to store the email input
  const [password, setPassword] = useState(''); // State to store the password input
  const [confirmPassword, setConfirmPassword] = useState(''); // State to store the password input
  const [error, setError] = useState(''); // State to store any error message
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setError(''); // Reset error message
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const response = await registerUser(email, password, username, phone_number); // Use the registerUser function
    if (response === true) {
      navigate('/login');
    } else if (response === false) {
      setError('Failed to register. Please try again.');
    } else {
      var errors = ""
      for (let i = 0; i < response.errors.length; i++) {
        errors += response.errors[i] + ", ";
      }
      setError(errors);
    }
  };

  // Render the registration form
  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <h2 className="title">Register</h2>
          <div className="form-group">
            <input
              placeholder="Username"
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>

        <div>
          <div className="form-group">
            <input
              placeholder="Email"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div>
          <div className="form-group">
            <input
              placeholder="Phone Number"
              type="tel"
              id="number"
              value={phone_number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="form-group">
            <input
              placeholder="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div>
          <div className="form-group">
            <input
              placeholder="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        {error && <p className="center-text">{error}</p>}
        <div className="form-group">
          <button className="button" type="submit">Register</button>
        </div>
        <div className="signup-link">
          Already have an account?
          <br />
          <a href="/login">Log in</a>
        </div>
      </form>
    </div>
  );
};

export default RegisterPage;