import config from '../config';

  export const loginUser = async (email, password) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/users/sign_in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
        body: JSON.stringify({
          user: {
            email,
            password,
          },
        }),
      
      });

      if (response.ok) {
        const data = await response.json();
        return { success: true, id: data.id, email: data.email, username: data.username, profile_picture_url: data.profile_picture_url }; // Return a success indicator
      } else {
        // Handle non-OK responses and return the error message from the backend
        const data = await response.json();
        return { success: false, message: data.message }; // Include the error message
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  export const registerUser = async (email, password, username, phone_number) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
        body: JSON.stringify({
          user: {
            email,
            password,
            username,
            phone_number
          },
        }),
      
      });
  
      if (response.ok) {
        return true;
      } else {
      // Handle non-OK responses here, before calling response.json()
      const data = await response.json();
      return data;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  export const createPost = async (formData) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/posts`, {
        method: 'POST',
        credentials: 'include', // Include credentials if your backend expects them
        body: formData, // Send FormData, including images
      });
  
      if (response.ok) {
        return true;
      } else {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error('Error creating post:', error);
      return false;
    }
  };

  export const getPosts = async (page = 1, perPage = 10) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/posts?page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;  // Ensure this matches what the backend sends
      } else {
        // Handle non-OK responses here, maybe return an empty structure
        return { posts: [], total_pages: 0, total_count: 0 };
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      return { posts: [], total_pages: 0, total_count: 0 };  // Return an empty structure on error
    }
  };

  export const getFIlteredPosts = async (ids, page = 1, perPage = 10) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/filter_posts?ids=${ids}&page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;  // Ensure this matches what the backend sends
      } else {
        // Handle non-OK responses here, maybe return an empty structure
        return { posts: [], total_pages: 0, total_count: 0 };
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      return { posts: [], total_pages: 0, total_count: 0 };  // Return an empty structure on error
    }
  };

  export const getPost = async(id) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/posts/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
      
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
      // Handle non-OK responses here, before calling response.json()

      return false;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  export const getComments = async(id, page = 1, perPage = 10) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/posts/${id}/comments?page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
      
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
      // Handle non-OK responses here, before calling response.json()

      return false;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  export const createComment = async (comment, created_by, post_id) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include if your backend expects credentials
        body: JSON.stringify({
          comment: {
            comment,
            created_by,
            post_id
          },
        }),
      
      });
  
      if (response.ok) {
        return true;
      } else {
      const data = await response.json();
      return data;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

export const deleteComment = async (commentId) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/comments/${commentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include if your backend expects credentials
        body: JSON.stringify({
          commentId
        })
    });

    if (!response.ok) {
      throw new Error('Failed to delete comment');
    }

    return true;
  } catch (error) {
    console.error('Delete comment error:', error);
    return false;
  }
};

export const updateProfile = async (formData) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/profile`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.errors ? data.errors.join(', ') : 'Failed to update profile');
    }

    return data;
  } catch (error) {
    console.error('Update profile error:', error);
    return { errors: [error.message] };
  }
};

export const getProfile = async (user_id) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/profile/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include if your backend expects credentials
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
    // Handle non-OK responses here, before calling response.json()

    return false;
    }
  } catch (error) {
    console.error('Delete comment error:', error);
    return false;
  }
};

export const editUser = async (formData) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/user`, {
      method: 'POST',
      credentials: 'include',
      body: formData, // No need to set content-type, browser handles this automatically for FormData
    });

    return response;
  } catch (error) {
    console.error('Edit user error:', error);
    return false;
  }
};

export const getUser = async (user_id) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/user/${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include if your backend expects credentials
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
    return false;
    }
  } catch (error) {
    console.error('Delete comment error:', error);
    return false;
  }
};

export const getTags = async () => {
  // try {
  //   const response = await fetch(`${config.apiBaseUrl}/getTags`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     credentials: 'include', // Include if your backend expects credentials
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     return data;
  //   } else {
  //   return false;
  //   }
  // } catch (error) {
  //   console.error('Tag error:', error);
  //   return false;
  // }

  // hard coding tags to reduce the number of requests to the backend
  return [
    { id: 1, name: 'Braids' },
    { id: 2, name: 'Locs' },
    { id: 67, name: 'Beauty Store' },
    { id: 34, name: "Men's Trims" },
    { id: 68, name: 'Wigs' },
    { id: 69, name: 'Weaves' },
    { id: 70, name: 'Cornrows' },
  ];
};

export const getUserPosts = async (userId, page = 1, perPage = 10) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/specific_user_posts?id=${userId}&page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include if your backend expects credentials
    });

    if (response.ok) {
      const data = await response.json();
      return data;  // Return the posts data
    } else {
      return { posts: [], total_pages: 0, total_count: 0 };  // Return empty structure on failure
    }
  } catch (error) {
    console.error('Error fetching user posts:', error);
    return { posts: [], total_pages: 0, total_count: 0 };  // Return empty structure on error
  }
};

export const getProfiles = async () => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include if your backend expects credentials
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
    // Handle non-OK responses here, before calling response.json()

    return false;
    }
  } catch (error) {
    console.error('Delete comment error:', error);
    return false;
  }
};

export const search = async (query) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/search?query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include if your backend expects credentials
    });

    if (response.ok) {
      const data = await response.json();
      return data;  // Return the posts data
    }
  } catch (error) {
    console.error('Error fetching user posts:', error);
    return { posts: [], total_pages: 0, total_count: 0 };  // Return empty structure on error
  }
};

export const createEnquiry = async (name, email, phone, message) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/enquiry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include if your backend expects credentials
      body: JSON.stringify({
        enquire: {
          name,
          email,
          phone,
          message
        },
      }),
    
    });

    return true;
  } catch (error) {
    console.error('Login error:', error);
    return false;
  }
};

export const deletePost = async (post_id) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/posts/${post_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include if your backend expects credentials
        body: JSON.stringify({
          post_id
        })
    });

    if (!response.ok) {
      throw new Error('Failed to delete comment');
    }

    return true;
  } catch (error) {
    console.error('Delete comment error:', error);
    return false;
  }
};

export const getUserByUsername = async(username) => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/username_search/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include if your backend expects credentials
    
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
    // Handle non-OK responses here, before calling response.json()

    return false;
    }
  } catch (error) {
    console.error('Login error:', error);
    return false;
  }
};