import React, { useState } from "react";
import { createEnquiry } from '../../utils/api.jsx'; // Import the createEnquiry function
import '../generalStyle.css';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setNumber] = useState('');
  const [message, setEnquiry] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();  // Prevent the page from refreshing
    const response = await createEnquiry(name, email, phone, message);
    if (response === true) {
      setResponse('Enquiry submitted successfully');
    } else {
      setResponse('Error while submitting enquiry');
    }
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <h2 className="title">Contact Us</h2>

        <div className="form-group">
          <span className="center-text">
            If you have any questions or concerns, please fill out the form below and we will get back to you as soon as possible.
          </span>
        </div>

        <div className="form-group">
          <input
            placeholder="Name"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <input
            placeholder="Email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <input
            placeholder="Phone Number"
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>

        <div className="form-group">
          <textarea
            className="content-input"
            placeholder="Please enter your enquiry here"
            id="enquiry"
            value={message}
            onChange={(e) => setEnquiry(e.target.value)}
            required
          />
        </div>

        {response && <p className="center-text">{response}</p>}

        <div className="form-group">
          <button className="button" type="submit">Submit Enquiry</button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
