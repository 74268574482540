const apiBaseUrl = process.env.NODE_ENV === 'production'
  ? 'https://glimmer-backend-43e20f1b2582.herokuapp.com'
  : 'https://localhost:3001';

const actionCableUrl = apiBaseUrl.replace(/^http/, 'ws').replace(/^https/, 'wss') + '/cable';  // Correctly handle http and https

const config = {
  apiBaseUrl,
  actionCableUrl,
};

export default config;
