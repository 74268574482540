import React, { createContext, useContext, useState } from 'react';
import { loginUser } from '../utils/api'; // Import the loginUser function

const AuthContext = createContext(); // Create a new context

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Provider component that wraps your app and provides auth state
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check local storage (or session storage) for an auth flag/token
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  // Function to log in the user
  const login = async (email, password) => {
    const response = await loginUser(email, password); // Use the loginUser function
    const defaultProfilePicture = process.env.PUBLIC_URL + '/Default_pfp.png';

    if (response.success) {
      // Update the authentication status based on success
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('email', response.email);
      localStorage.setItem('userId', response.id);
      localStorage.setItem('username', response.username);
      if (response.profile_picture_url) {
        localStorage.setItem('profilePictureUrl', response.profile_picture_url);
      } else {
        localStorage.setItem('profilePictureUrl', defaultProfilePicture)
      }
    } else {
      // Handle the error here, maybe return the error message to the calling component
      console.error(response.message);
    }
    return response; // Return the full response to the caller (including success and message)
  };

  // Function to log out the user
  const logout = () => {
    localStorage.removeItem('isAuthenticated'); // Remove the flag from storage
    localStorage.removeItem('email');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('profilePictureUrl')
    setIsAuthenticated(false); // Update the authentication status
  };

  // Provide the authentication state and functions to the component tree
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
