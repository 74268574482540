  // NavigationBar.js
  import React, { useState } from 'react';
  import '../generalStyle.css';
  import { useAuth } from '../../context/Authentication';
  import PlusIcon from './PlusIcon';
  import { useNavigate } from 'react-router-dom';
  import CustomDropdown from './MyProfile';
  import { search as searchApi } from '../../utils/api';
  import { Link } from 'react-router-dom';

  const logo = process.env.PUBLIC_URL + '/logo.png';

  const NavigationBar = () => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState({ users: [], posts: [] });
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const userid = localStorage.getItem('userId');
    const { logout } = useAuth();

    const iconOnClick = () => {
      navigate('/post');
    };

    const handleSearch = async (query) => {
      if (query.length < 3) {
        setResults({ users: [], posts: [] });
        return;
      }
      const data = await searchApi(query);
      setResults(data);
    };

    const handleInputChange = (e) => {
      const value = e.target.value;
      setQuery(value);

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(setTimeout(() => {
        handleSearch(value);
      }, 300));
    };

    const clearSearch = () => {
      setQuery('');
      setResults({ users: [], posts: [] });
    };

    const handleLogout = async (event) => {
      event.preventDefault();
      await logout();
      setIsMenuOpen(false)
      navigate('/login', { replace: true });
    };

    return (
      <div className="navbar">
          <Link to="/">
            <img className="home" src={logo} alt="Home" />
          </Link>

            <div className="search-container">
              <input
                type="text"
                className='search'
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
              />
              {query.length >= 3 && (
                <div className="search-results">
                  {results.users.length > 0 && (
                    <div>
                      <h3>User Results:</h3>
                      {results.users.map(user => (
                        <Link key={user.id} to={`/profile/${user.id}`} onClick={clearSearch}>
                          <div className="search-result-item">{user.username}</div>
                        </Link>
                      ))}
                    </div>
                  )}
                  {results.posts.length > 0 && (
                    <div>
                      <h3>Post Results:</h3>
                      {results.posts.map(post => (
                        <Link key={post.id} to={`/post/${post.id}`} onClick={clearSearch}>
                          <div className="search-result-item">{post.title}</div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

              
              {isAuthenticated ? 
                <>
                  <PlusIcon color='grey' size='30' onClick={iconOnClick} /> 
                  <CustomDropdown /> 
                </>
              : 
                <Link className="login" to="/login">Login</Link>
                }

          {/* Hamburger Menu Icon for Mobile */}
          <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            ☰
        </div>

        {/* Collapsible Menu for Mobile */}
        {isMenuOpen && (
          <div className="mobile-menu">

            {isAuthenticated ? (
              <>
                <Link to="/editprofile" onClick={() => setIsMenuOpen(false)}>Edit Profile</Link>
                <Link to="/editaccount" onClick={() => setIsMenuOpen(false)}>Edit Account</Link>
                <Link to={`/profile/${userid}`} onClick={() => setIsMenuOpen(false)}>My Profile</Link>

                <a onClick={handleLogout}>Log Out</a>

                <a className="no-hover"></a>
                  <Link to="/post" onClick={() => setIsMenuOpen(false)}>Create Post</Link>
              </>
            ) : (
              <>
                <Link to="/login" onClick={() => setIsMenuOpen(false)}>Login</Link>
                <Link to="/signup" onClick={() => setIsMenuOpen(false)}>Register</Link>
              </>
            )}
            <a className="no-hover"></a>
            <Link to="/findastylist" onClick={() => setIsMenuOpen(false)}>Hair Stylists</Link>
            <a className="no-hover"></a>
            <Link to="/aboutus" onClick={() => setIsMenuOpen(false)}>About Us</Link>
            <Link to="/contactus" onClick={() => setIsMenuOpen(false)}>Contact Us</Link>
            <Link to="/termsofservice" onClick={() => setIsMenuOpen(false)}>Terms Of Service</Link>
            <Link to="/privacy" onClick={() => setIsMenuOpen(false)}>Privacy Policy</Link>
          </div>
        )}
      </div>
    );
  };

  export default NavigationBar;
